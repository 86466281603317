.inline-link {
  cursor: pointer;
  color: #fff;
  transition: color 0.2s;
  &:hover {
    color: rgb(133, 133, 255);
  }
  &:active {
    color: #fff;
  }
}

.ag-theme-balham {
  .ag-header-cell {
    padding-left: 1px;
    padding-right: 1px;
  }

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-row-focus {
    background-color: rgba(128, 128, 128, 0.212);
  }

  .grid-status-selector {
    .ant-select-selection-item {
      line-height: 25px;
      font-size: 13px;
      color: #4e4e4e;
    }
  }
  .ag-filter-body-wrapper
    .ag-set-filter-list
    .ag-set-filter-item
    .ag-set-filter-item-checkbox
    .ag-input-field-label {
    height: 17px;
    overflow: hidden;
    word-wrap: hidden;
  }
}

.listed-toggler {
  margin: 0 10px;

  &.ant-switch {
    background-color: buttonface;
    .ant-switch-inner {
      color: grey;
    }
  }

  &.ant-switch-checked {
    background-color: #1890ff;
    .ant-switch-inner {
      color: #fff;
    }
  }
}
