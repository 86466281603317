@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #root {
    height: 100vh;
    .app-body {
      height: calc(100% - 50px);
      .plus-button {
        .anticon {
          line-height: 13px;
        }
      }
    }
    .navigation {
      .anticon-loading {
        position: absolute;
        left: 50%;
        top: 10px;
        font-size: 29px;
        color: #cbcbcb;
      }
    }
  }

  .ant-btn-primary {
    color: #fff;
    border-color: #1890ff;
    background: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }

  .grid-long-value-tooltip {
    .ant-tooltip-content {
      width: 500px;
    }
  }

  .login-modal-img {
    .login-img {
      height: 200px;
      width: 200px;
      background: url("./fav01.png") no-repeat center center / contain;
      border-radius: 10px;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
